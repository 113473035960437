import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { InvoiceSlice, createInvoiceSlice } from './invoice';

export const useStore = create<InvoiceSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createInvoiceSlice(...a),
      }),
      {
        partialize: state => ({
          initialLink: state.initialLink,
          userInfo: state.userInfo,
        }),
        name: 'qlub_payment_link_store',
      },
    ),
  ),
);
