import { useRouter } from 'next/router';

import { useQuery } from '@tanstack/react-query';

import { getRestaurantInfo } from '@/services';
import { useStore } from '@/store';
import { RestaurantInfoEffectProps } from '@/types';

export function useRestaurant(props?: RestaurantInfoEffectProps) {
  const router = useRouter();
  const q = router.query;
  const cc = q.cc as string;
  const slug = q.slug as string;

  const toggleLoader = useStore(state => state.toggleLoader);

  const query = useQuery({
    queryKey: ['restaurant-info', cc, slug],
    queryFn: () =>
      getRestaurantInfo({
        cc,
        restaurantUnique: slug,
      }).then(res => {
        if (!q.lang && res.config.paymentLinkDefaultLang) {
          router.push(
            {
              pathname: router.pathname,
              query: { ...q, lang: res.config.paymentLinkDefaultLang },
            },
            undefined,
            { shallow: true },
          );
        }
        if (props?.shouldUpdateLoader) toggleLoader({ restaurant: false });
        return res;
      }),
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    enabled: !!cc && !!slug,
  });

  return query;
}
