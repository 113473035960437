import { ReactNode, useEffect, useMemo, useState } from 'react';

import { CacheProvider, EmotionCache } from '@emotion/react';

import { createEmotionRtlCache, themeRtl, useGetTheme } from '@/config';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { detectRTLFromLang } from '@clubpay/customer-common-module/src/utility/k_lang';
import { ThemeProvider } from '@mui/material/styles';

type Props = {
  children: ReactNode;
  emotionCache: EmotionCache;
};

const ExtendedThemeProvider = ({ children, emotionCache }: Props) => {
  const { lang, theme: userTheme } = useQlubRouter();
  const { theme } = useGetTheme();
  const [rtl, setRtl] = useState(false);

  useEffect(() => {
    if (!lang) return;
    const isRtl = detectRTLFromLang(lang);
    const el = document.querySelector('html');
    if (el) {
      el.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
      el.setAttribute('lang', lang);
      el.setAttribute(
        'data-theme',
        userTheme === 'black' ? 'black' : 'default',
      );
    }
    setRtl(isRtl);
  }, [lang, userTheme]);

  const [cache] = useMemo(() => {
    const getCache = () => {
      if (rtl) return createEmotionRtlCache();
      return emotionCache;
    };

    const getThemeVariation = () => {
      if (rtl) return themeRtl(theme);
      return theme;
    };

    return [getCache(), getThemeVariation()];
  }, [rtl]);

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

export default ExtendedThemeProvider;
