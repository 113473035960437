import { TipModeEnum } from '@/types';

export enum CurrentStatuses {
  CREATED = 'created',
  DISCARDED = 'discarded',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  EXPIRED = 'expired',
}

export type CalculateTipProps = {
  remaining: string | undefined;
  selectedTip: number | undefined;
  tipMode: TipModeEnum;
  customTip: string;
  isTipEnabled?: boolean;
};

export type UserInfo = {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  dsi: string;
  saved?: boolean;
  receiptSent?: boolean;
};

export type UseSaveCustomerDataEffectProps = {
  ref?: string;
  enabled?: boolean;
};
