import { Inter } from 'next/font/google';

import { TypographyOptions } from '@mui/material/styles/createTypography';

export const inter = Inter({ subsets: ['latin'] });

export const extendColors = ({ ownerState, theme }: any) => ({
  ...(ownerState.color && {
    color: theme.palette[ownerState.color]?.main || undefined,
  }),
});

export const typography: TypographyOptions = {
  display_lg: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '3.6875rem',
    letterSpacing: '0',
  },
  display_md: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '2.9375rem',
    letterSpacing: '0',
  },
  display_sm: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '2.5rem',
    letterSpacing: '0',
  },
  headline_lg: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '2.0625rem',
    letterSpacing: '0.0025em',
  },
  headline_md: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '1.4375rem',
    letterSpacing: '0',
  },
  headline_sm: {
    fontFamily: 'inherit',
    fontWeight: '500',
    fontSize: '1.1875rem',
    letterSpacing: '0.0015em',
  },
  title_lg: {
    fontFamily: 'inherit',
    fontWeight: '500',
    fontSize: '1.4375rem',
    letterSpacing: '0',
  },
  title_md: {
    fontFamily: 'inherit',
    fontWeight: '500',
    fontSize: '1rem',
    letterSpacing: '0.0015em',
  },
  title_sm: {
    fontFamily: 'inherit',
    fontWeight: '500',
    fontSize: '0.875rem',
    letterSpacing: '0.001em',
  },
  body_md: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '1rem',
    letterSpacing: '0.005em',
  },
  body_sm: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '0.875rem',
    letterSpacing: '0.0025em',
  },
  body_sm_strike: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '0.875rem',
    letterSpacing: '0.0025em',
    textDecorationLine: 'line-through',
  },
  button: {
    fontFamily: 'inherit',
    fontWeight: '500',
    fontSize: '0.875rem',
    letterSpacing: '0.25px',
  },
  caption: {
    fontFamily: 'inherit',
    fontWeight: '400',
    fontSize: '0.75rem',
    letterSpacing: '0.004em',
  },
  currency: {
    fontSize: '0.625rem',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0.04px',
  },
};
