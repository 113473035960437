import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import createCache from '@emotion/cache';

const isBrowser = typeof document !== 'undefined';

export default function createEmotionRtlCache() {
  let insertionPoint;

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector<HTMLMetaElement>(
      'meta[name="emotion-insertion-point"]',
    );
    insertionPoint = emotionInsertionPoint ?? undefined;
  }

  return createCache({
    key: 'mui-rtl-style',
    insertionPoint,
    stylisPlugins: [prefixer, rtlPlugin],
  });
}
