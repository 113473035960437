import { IGetRestaurantPayload, IRestaurant } from '@/types';
import axiosInstance from '@clubpay/customer-common-module/src/repository/axios';
import { parseVendor } from '@clubpay/customer-common-module/src/repository/vendor/transformer';

export async function getRestaurantInfo({
  cc,
  restaurantUnique,
}: IGetRestaurantPayload): Promise<IRestaurant> {
  const res = await axiosInstance.Get<IRestaurant>(
    `/restaurant/${cc}/${restaurantUnique}`,
  );
  return parseVendor(res.data as any) as IRestaurant;
}
