export enum TipModeEnum {
  Default = 'default',
  Fixed = 'fixed',
  Percentage = 'percentage',
  PercentageWithHint = 'percentageWithHint',
  FixedWithHint = 'fixedWithHint',
}

export interface ISplashConfig {
  splashEnable?: boolean;
  splashTitle?: string;
  splashDesc?: string;
  splashTheme?: SplashConfigEnum;
}

export enum IMenuPageMode {
  Old = 'old',
  New = 'new',
}

export enum SplitModeTypeEnum {
  empty = '',
  byItem = 'byItem',
  byShare = 'byShare',
  custom = 'custom',
  unknown = 'unknown',
}

export interface IPaymentGatewayView {
  name: string;
  config: any;
  id: string;
  disabled?: boolean;
  type?: any;
}

export interface IPaymentGatewayMany {
  itemsList: Array<IPaymentGatewayView>;
  total: number;
  hasPgGroup: boolean;
}

export enum TipTooltipModeEnum {
  Hide = 'hide',
  Once = 'once',
  OncePerVendor = 'once_per_vendor',
  Always = 'always',
  AlwaysWithTip = 'always_with_tip',
  Show = 'show',
}

export enum SplitModeEnum {
  Disabled = 'disabled',
  Legacy = 'legacy',
  New = 'new',
}

export enum MenuModeEnum {
  PDFMenu = 'pdfMenu',
  ExternalUrl = 'externalUrl',
  ExternalUrlSameTab = 'externalMenuSameTab',
  MultiMenu = 'multiMenu',
  TableUrl = 'tableUrl',
  MultiMenuTab = 'multiMenuTab',
  PdfMenuIOSRedirect = 'pdfMenuIOSRedirect',
}

type voucherTypes =
  | 'promotion'
  | 'promotionDark'
  | 'survey'
  | 'surveyDark'
  | 'general'
  | 'generalDark';
export type emailTermsAndConditionsType = 'hide' | 'show';

export enum TipGamificationModeEnum {
  Default = 'default',
  GamificationOn = 'gamificationOn',
  GamificationOff = 'gamificationOff',
}

export enum TipModalTriggerPointConditionEnum {
  TapOnPay = 'tapOnPay',
  ConfirmPaymentType = 'confirmPaymentType',
}

export enum TipModalEventConditionEnum {
  ZeroAmount = 'zeroAmount',
  Interaction = 'interaction',
}

export enum MenuPageDescriptionModeEnum {
  TableId = 'tableId',
  TableName = 'tableName',
  PreferredTableName = 'preferredTableName',
  RestaurantName = 'restaurantName',
  Custom = 'custom',
  NoDescription = 'noDescription',
}

export enum BillPageDescriptionModeEnum {
  TableId = 'tableId',
  TableName = 'tableName',
  PreferredTableName = 'preferredTableName',
}

export enum IRestaurantConfigRedirectPatch {
  IdAfterHash = 'idAfterHash',
}

export enum OrderSummaryMode {
  Hide = 'hide',
  Show = 'show',
}

export enum SplashConfigEnum {
  White = 'white',
  Black = 'black',
  Purple = 'purple',
}

export enum ConfirmationPageRedirectEnum {
  Home = 'home',
  Menu = 'menu',
}

export enum EnableEnum {
  Enable = 'enable',
  Disable = 'disable',
}

export enum ReviewServiceTypeEnum {
  WithGoogleReview = 'withGoogleReview',
  WithoutGoogleReview = 'withoutGoogleReview',
}

export enum LandingMenuButtonFirst {
  True = 'true',
  False = 'false',
}

export interface IRestaurantConfig extends ISplashConfig {
  hideSurchargeSubtitle?: boolean;
  previewBill: boolean;
  hideTableName?: boolean;
  hideTableId?: boolean;
  pdfPriority?: string[];
  externalMenuUrl?: string;
  externalMenuSameTab?: boolean;
  vendorInstruction?: string;
  emailTermsAndConditionsV4?: emailTermsAndConditionsType;
  tipOptions?: number[];
  tipMode?: TipModeEnum;
  tipDefault?: number;
  tipTooltip?: TipTooltipModeEnum;
  tipReminderContent?: string;
  tipHide?: boolean;
  splitMode: SplitModeEnum;
  hideLogo?: boolean;
  menuLinks?: string;
  splitOptions?: SplitModeTypeEnum[];
  menuMode?: MenuModeEnum;
  tableMenu?: string;
  tableUrl?: string;
  socialMedia?: string;
  socialMediaHeader?: string;
  showOrderDetails?: string;
  showTipValueDetails?: string;
  hideViewMenuButton?: boolean;
  viewMenuText?: string;
  customerAppColorMode?: string;
  qsrModalShow?: boolean;
  showTableNameInMenu?: boolean;
  comoEnable?: boolean;
  comoBannerDescription?: string;
  comoBannerTitle?: string;
  voucherDescription?: string;
  voucherTitle?: string;
  voucherStyle?: string;
  voucherType?: voucherTypes;
  voucherUrl?: string;
  mostCommonTip?: number;
  tipGamificationMode?: TipGamificationModeEnum;
  tipModalTriggerCondition?: TipModalTriggerPointConditionEnum;
  tipModalEventCondition?: TipModalEventConditionEnum;
  tipDefaultForTipModal?: number;
  tipModalWithPayment?: boolean;
  tipShowZeroValue?: boolean;
  hideTipAmountTipBox?: boolean;
  defaultLang?: string;
  preventLogoBlur?: boolean;
  tipModalHideNotNow?: boolean;
  queueNumberDigit?: number;
  showQueueNumber?: boolean;
  onlyMenuVendor?: boolean;
  menuPageTitle?: string;
  billPageDescriptionMode?: BillPageDescriptionModeEnum;
  menuPageDescriptionMode?: MenuPageDescriptionModeEnum;
  menuPageDescription?: string;
  menuPageMode?: IMenuPageMode;
  redirectPatch?: IRestaurantConfigRedirectPatch;
  redirectPreventCircular?: boolean;
  promotionalBannerImageLink?: string;
  promotionalBannerPlacement?: string;
  promotionalBannerRedirectLink?: string;
  promotionalBannerSize?: string;
  enablePromotionalBanner?: boolean;
  voucherHasActiveCampaign?: EnableEnum;
  orderSummary?: OrderSummaryMode;
  enableMenuCTAButton?: boolean;
  menuCTAButtonText?: string;
  dinerFeeTooltipText?: string;
  showDinerFeeTooltip?: string;
  enableTipRounding?: boolean;
  enableUserProfile?: EnableEnum;
  splashEnable?: boolean;
  splashTitle?: string;
  splashDesc?: string;
  splashHideTypography?: boolean;
  splashTheme?: SplashConfigEnum;
  confirmationPageRedirect?: ConfirmationPageRedirectEnum;
  prependQueueNo?: string;
  disableSplitWithPromo?: boolean;
  queueNumberDisplayGroup?: string[];
  exchangeInterruptionMessage?: string;
  reviewEnabled?: EnableEnum;
  reviewMode?: ReviewServiceTypeEnum;
  defaultRate?: number;
  goodRatingThreshold?: number;
  landingMenuButtonFirst?: LandingMenuButtonFirst;
  paymentLinkCollectCustomerEmail?: boolean;
  paymentLinkCollectCustomerMobile?: boolean;
  paymentLinkTipDefault?: number;
  paymentLinkTipEnabled?: boolean;
  paymentLinkTipMode?: TipModeEnum;
  paymentLinkTipOptions?: number[];
  paymentLinkDefaultLang?: string;
}

export enum DiscountTypeEnum {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export enum ZeroPriceModeEnum {
  Zero = 'zero',
  Free = 'free',
  Null = 'null',
}

export interface IDiscountType {
  amount: string;
  type: DiscountTypeEnum;
}

export enum StyleEnum {
  TitleMedium = 'titleMedium',
  TitleSmall = 'titleSmall',
  BodyMedium = 'bodyMedium',
  BodySmall = 'bodySmall',
  BodyMediumStrike = 'bodyMediumStrike',
  BodySmallStrike = 'bodySmallStrike',
}

export enum IconEnum {
  Dollar = 'dollar',
  Yen = 'yen',
  Rial = 'rial',
  Percent = 'percent',
  Bill = 'bill',
  ItemPercent = 'itemPercent',
  ItemBill = 'itemBill',
  Discount = 'discount',
}

export enum ColorEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  HighEmphasis = 'highEmphasis',
  MediumEmphasis = 'mediumEmphasis',
  LowEmphasis = 'lowEmphasis',
  Background = 'background',
  Green = 'green',
}

export enum ConditionEnum {
  Default = '',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  NE = 'ne',
  E = 'e',
  GTD = 'gtd',
}

export type ILayoutLocale = {
  locale: string;
  value: string;
};

export interface ILayout {
  key: string;
  style?: StyleEnum | null;
  icon?: IconEnum | null;
  color?: ColorEnum | null;
  format?: string | null;
  vcond?: ConditionEnum | null;
  hideAltValue?: boolean;
  locales?: ILayoutLocale[];
}

export interface IRestaurantCountryConfig {
  paymentLinkCustomerCommissionAlias?: string;
}

export interface IRestaurantCountry {
  name: string;
  code: string;
  isoCode: string;
  phonePrefix: string;
  languageCode: string;
  currencyCode: string;
  currencySymbol: string;
  config: IRestaurantCountryConfig;
}

export interface IRestaurantTimezone {
  countryCode: string;
  timezone: string;
}

export interface IRestaurantPos {
  config?: { [key: string]: any } & ISplashConfig;
  billLayout?: ILayout[];
  itemLayout?: ILayout[];
}

export interface IRestaurantBrand {
  name: string;
  id: string;
}

export interface IRestaurantPaymentGateway {
  id: string;
  name: string;
  countryCode: string;
  config: { [key: string]: any };
}

export enum CustomerDetailsInterfaceEnum {
  Modal = 'modal',
  Inline = 'inline',
}

export enum CustomerDetailsModeEnum {
  None = 'none',
  OrderDetails = 'orderDetails',
  Preparation = 'preparation',
}

export enum OrderPaymentMethodEnum {
  Default = 'default',
  PayLater = 'payLater',
  NoPayment = 'noPayment',
}

export enum OrderPricePresentationEnum {
  Default = 'default',
  HidePrice = 'hidePrice',
}

export enum OrderPriceRoundingModeEnum {
  Round = 'round',
  Ceil = 'ceil',
  Floor = 'floor',
}

export enum OrderIdPresentationEnum {
  RefId = 'refId',
  TicketId = 'ticketId',
  TicketIdOrRefId = 'ticketIdOrRefId',
  TicketIdAndRefId = 'ticketIdAndRefId',
}

export enum OrderUnavailableItemPresentationEnum {
  HideCategoryHint = 'hideCategoryHint',
  HideCategory = 'hideCategory',
  HideProductHint = 'hideProductHint',
  HideProduct = 'hideProduct',
}

export enum OrderCustomerDetailsKeyEnum {
  Name = 'name',
  Phone = 'phone',
  Address = 'address',
  Email = 'email',
  CarPlate = 'carPlate',
  CPF = 'cpf',
}

export enum OrderModifierValidationIgnore {
  Min = 'min',
  Max = 'max',
  MultiMax = 'multiMax',
  DistinctMax = 'distinctMax',
}

export enum MenuViewDefaultEnum {
  List = 'list',
  Grid = 'grid',
}

export interface IRestaurantOrderConfig {
  manualAmount: boolean;
  invoiceShow: boolean;
  categoryAllItems: boolean;
  deliveryEnable: boolean;
  splitEnable: boolean;
  basketEdit?: boolean;
  hideBundlePrice?: boolean;
  orderHistoryDisable?: boolean;
  zeroPriceMode?: any;
  statusTitle?: any;
  statusDesc?: any;
  preparationInfoDisable?: boolean;
  preparationInfoText?: string;
  menuInfoDisable?: boolean;
  menuInfoText?: string;
  customerDetailsFields?: OrderCustomerDetailsKeyEnum[];
  customerDetailsRequiredFields?: OrderCustomerDetailsKeyEnum[];
  customerDetailsMode?: CustomerDetailsModeEnum;
  customerDetailsInterfaceMode?: CustomerDetailsInterfaceEnum;
  customerDetailsAllowEdit?: boolean;
  paymentMethod?: OrderPaymentMethodEnum;
  pricePresentation?: OrderPricePresentationEnum;
  priceRoundingMode?: OrderPriceRoundingModeEnum;
  orderIdPresentation?: OrderIdPresentationEnum;
  unavailableItemPresentation?: OrderUnavailableItemPresentationEnum[];
  defaultOrderStatus?: string;
  menuListWithLogo?: boolean;
  multiOrder?: boolean;
  modifierValidationIgnore?: OrderModifierValidationIgnore[];
  qrPasscodeEnable?: boolean;
  menuOnlyRestaurant?: boolean;
  menuViewDefault?: MenuViewDefaultEnum;
  hideComment?: boolean;
  scheduledOrderEnable?: boolean;
  scheduledOrderPreparationTime?: number;
  scheduledOrderOptionGap?: number;
  scheduledOrderFutureDays?: number;
}

export interface IRestaurantQRMeta {
  menuUrl?: string;
  redirectionUrl?: string;
  group?: string;
}

export enum OrderModeEnum {
  Default = 'default',
  DigitalMenu = 'digital_menu',
  QSR = 'qsr',
}

export interface IRestaurant {
  id: string;
  unique: string;
  name: string;
  phone: string;
  email: string;
  title: string;
  address1: string;
  address2: string;
  area: string;
  city: string;
  country: IRestaurantCountry;
  externalMenuUrl: string;
  externalMenuFormat: string | null;
  primaryColour: string;
  secondaryColour: string;
  backgroundUrl: string;
  logoBigUrl: string;
  logoSmallUrl?: string;
  googlePlaceId?: string;
  opsMode: string | null;
  config: IRestaurantConfig;
  brand: IRestaurantBrand;
  timezone: IRestaurantTimezone | null;
  posVendor: IRestaurantPos | null;
  paymentGateways?: IRestaurantPaymentGateway[];
  paymentGatewayList: IPaymentGatewayMany;
  discount?: IDiscountType;
  redirect?: string;
  orderMode: OrderModeEnum | null;
  orderConfig?: IRestaurantOrderConfig;
  metaObject?: IRestaurantQRMeta;
  meta?: string;
  availablePGs?: string;
}

export interface IExchangeData {
  amount?: string;
  amountCalculated: string;
  oldCurrencyCode?: string;
  newCurrencyCode: string;
  exchangeRate?: string;
}

export interface IGetQrDetailPayload {
  cc: string;
  restaurantUnique: string;
  tableID: string;
  f1?: string;
  f2?: string;
  hash?: string;
}

export interface IGetQrDetailConfig {
  ignoreCache?: boolean;
}

export interface IQrDetailsResponse {
  table: {
    id: string;
    preferredName: string;
    revenueCenter: string;
    section: string;
    tableName: string;
  };
}

export interface IPaymentQrCache {
  time: number;
  details: IQrDetailsResponse;
}

export interface IGetRestaurantPayload {
  cc: string;
  restaurantUnique: string;
}

export type RestaurantInfoEffectProps = {
  shouldUpdateLoader?: boolean;
};
