import { extendColors, inter, typography } from '@/config/theme/common';
import { createTheme } from '@mui/material';
import blackTheme from '@qlub-dev/qlub-kit/dist/theme/blackTheme';
import { palette } from '@qlub-dev/qlub-kit/dist/tokens/palette';
import { paletteDark } from '@qlub-dev/qlub-kit/dist/tokens/paletteDark';

const theme = createTheme({
  palette: {
    ...blackTheme.palette,
    error: blackTheme.qlub.palette.danger,
    success: blackTheme.qlub.palette.success,
  },
  qlub: blackTheme.qlub,
  typography: typography,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title_sm: 'p',
        },
      },
      styleOverrides: {
        root: (props: any) => ({
          ...extendColors(props),
          fontFamily: inter.style.fontFamily,
        }),
      },
    },

    MuiDivider: {
      defaultProps: {
        sx: {
          borderColor: paletteDark.dim,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          borderRadius: '100px',
          fontSize: '1rem',
        },
        contained: {
          background: palette.black.main,
          color: palette.white.main,
          border: `solid 1px transparent`,
          boxShadow: 'none',

          '&:hover': {
            background: palette.black.main,
            boxShadow: 'none',
          },
          '&:focus': {
            background: palette.black.main,
            boxShadow: 'none',
          },
          '&:active': {
            background: palette.black.main,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            border: '1px solid transparent',
            boxShadow: '0',
            background: palette.dim.main,
            color: palette.onSurfaceColors.disabled,
          },
        },
        text: {
          background: '#FFFFFF',
          border: `solid 1px transparent`,
          '&:focus': {
            border: `solid 1px ${palette.black.main}`,
          },
          '&:hover': {
            background: '#FFFFFF',
          },
        },
        outlined: {
          backgroundColor: '#ffff',
          border: `1px solid ${palette.dim.main}`,
          '&:hover': {
            border: `1px solid ${palette.dim.main}`,
            background: palette.white.hover,
          },
          '&:focus': {
            border: `1px solid ${palette.black.main}`,
            background: palette.white.focus,
          },
          '&:active': {
            border: `1px solid ${palette.dim.main}`,
            background: palette.white.pressed,
          },
          '&.Mui-disabled': {
            border: '1px solid transparent',
            boxShadow: '0',
            background: palette.dim.main,
            color: palette.onSurfaceColors.disabled,
          },
        },
      },
      variants: [
        {
          props: {
            variant: 'containedLight',
          },
          style: {
            background: palette.white.main,
            color: palette.black.main,
            border: `solid 1px ${palette.dim.main}`,
            '&:focus': {
              border: `solid 1px ${palette.dim.main}`,
              background: palette.white.focus,
            },
            '&:hover': {
              background: palette.white.hover,
            },
            '&:active': {
              background: palette.white.pressed,
            },
            '&.Mui-disabled': {
              border: 'none',
              boxShadow: '0',
              background: palette.dim.main,
              color: palette.onSurfaceColors.disabled,
            },
          },
        },
        {
          props: {
            variant: 'containedDestructive',
          },
          style: {
            background: palette.destructive.main,
            color: palette.danger.main,
            border: `solid 1px transparent`,
            '&:focus': {
              border: `solid 1px ${palette.destructive.main}`,
              background: palette.destructive.focus,
            },
            '&:hover': {
              border: 'none',
              background: palette.destructive.hover,
            },
            '&:active': {
              border: 'none',
              background: palette.destructive.pressed,
            },
            '&.Mui-disabled': {
              border: 'none',
              boxShadow: '0',
              background: palette.dim.main,
              color: palette.onSurfaceColors.disabled,
            },
          },
        },
        {
          props: {
            variant: 'textElevated',
          },
          style: {
            boxShadow:
              '0px 2px 2px rgba(97, 100, 117, 0.06), 0px 3px 1px rgba(97, 100, 117, 0.04), 0px 1px 5px rgba(97, 100, 117, 0.12)',
            background: palette.white.main,
            color: palette.black.main,
            '&:focus': {
              border: `solid 1px ${palette.black.main}`,
              background: palette.white.focus,
            },
            '&:hover': {
              background: palette.white.hover,
            },
            '&:active': {
              background: palette.white.pressed,
            },
            '&.Mui-disabled': {
              boxShadow: '0',
              background: palette.dim.main,
              color: palette.onSurfaceColors.disabled,
            },
          },
        },
        {
          props: {
            variant: 'grey',
          },
          style: {
            fontWeight: '400',
            fontSize: '0.875rem',
            background: palette.grey.main,
            color: palette.onSurfaceColors.highEmphasis,
            '&:focus': {
              background: palette.grey.main,
            },
            '&:hover': {
              background: palette.grey.main,
            },
            '&:active': {
              background: palette.grey.main,
            },
            '&.Mui-disabled': {
              boxShadow: '0',
              background: palette.dim.main,
              color: palette.onSurfaceColors.disabled,
            },
          },
        },
      ],
    },

    MuiChip: {
      styleOverrides: {
        sizeMedium: {
          padding: '0 3px',
          height: '38px',
          borderRadius: '20px',
        },
        outlinedSecondary: {
          borderColor: palette.dim.main,
          color: palette.onSurfaceColors.highEmphasis,
          backgroundColor: palette.dim.transparent,
        },
        filledPrimary: {
          backgroundColor: palette.white.main,
          borderColor: palette.black.main,
          color: palette.black.main,
          borderWidth: 1,
          borderStyle: 'solid',
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          maxWidth: 'var(--app-max-width)',
          margin: '0 auto',
          borderRadius: '32px 32px 0 0',
          maxHeight: 'calc(100% - 16px)',
          '*': {
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',

            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
          '& :first-child>div>div>button': {
            padding: '8px',
            marginRight: '-8px',
          },
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '&.nonOutlined': {
            '& .MuiOutlinedInput-root': {
              background: palette.white.main,
              '&::placeholder': {
                color: palette.onSurfaceColors.disabled,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.black.main,
                borderWidth: '1px',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.danger.main,
                borderWidth: '1px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: 'transparent',
              },
              '&:hover:not(&.Mui-focused)': {
                background: palette.white.hover,
              },
              '&:focus:not(&.Mui-disabled)': {
                background: palette.white.focus,
              },
              '&.Mui-disabled': {
                background: palette.dim.main,
              },
            },
            '&.valid .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.black.main,
            },
          },
        },
      },
    },
  },
});

export { theme };
