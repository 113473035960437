// @ts-ignore
const en = require('../public/locales/en/common.json');
const ar = require('../public/locales/ar/common.json');
const pt = require('../public/locales/pt/common.json');
const tr = require('../public/locales/tr/common.json');
const zh = require('../public/locales/zh/common.json');

const i18n = {
  translations: {
    en,
    ar,
    pt,
    tr,
    zh,
  },
  defaultLang: 'en',
  useBrowserDefault: true,
};

module.exports = i18n;
