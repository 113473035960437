import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useAuth } from '@clubpay/customer-common-module/src/context/auth';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import ConfigRepo, {
  IConfig,
} from '@clubpay/customer-common-module/src/repository/config';

interface IConfigContext {
  config: IConfig | null;
}

const ConfigContext = createContext<IConfigContext>({
  config: null,
});

interface IProps {
  children?: ReactNode;
}

export function ConfigContextProvider({ children }: IProps) {
  const { asPath, isReady, url } = useQlubRouter();
  const [init, setInit] = useState(false);
  const [config, setConfig] = useState<IConfig | null>(null);
  const { login, isLogin } = useAuth();

  useEffect(() => {
    if (isLogin) {
      return;
    }

    login({ guest: true });
  }, [isLogin]);

  useEffect(() => {
    if (init || !url.cc) {
      return;
    }

    ConfigRepo.getInstance()
      ?.getConfig(url.cc)
      ?.then(res => {
        setConfig(res);
        setInit(true);
      })
      .catch(() => {
        setInit(true);
      });
  }, [isReady, url.cc, asPath]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  );
}

export const useConfigContext = () => useContext(ConfigContext);
