import { initializeApp } from 'firebase/app';
import { getPerformance, trace } from 'firebase/performance';

import { parseJSON } from '@clubpay/customer-common-module/src/utility/k_json';
import { FirebaseApp } from '@firebase/app';
import { FirebasePerformance } from '@firebase/performance/dist/src/public_types';

const firebaseConfig = parseJSON(process.env.NEXT_PUBLIC_FB_CONFIG || '');
const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || '';

export default class FirebaseService {
  public static getInstance() {
    if (!this.instance) {
      this.instance = new FirebaseService();
    }

    return this.instance;
  }

  private static instance: FirebaseService;

  private readonly app: FirebaseApp | undefined = undefined;

  private readonly perf: FirebasePerformance | undefined = undefined;

  public constructor() {
    if (!firebaseConfig) {
      return;
    }

    this.app = initializeApp(firebaseConfig);
    console.info('initializeApp');
    // Initialize Performance Monitoring and get a reference to the service
    this.perf = getPerformance(this.app);

    if (appVersion && this.perf) {
      const customTrace = trace(this.perf, 'custom_trace');
      customTrace.putAttribute('app_version', appVersion);
      customTrace.start();
      customTrace.stop();
      console.info('app_version', appVersion);
    }
  }

  public getApp() {
    return this.app;
  }

  public getPerformance() {
    return this.perf;
  }
}
