import { StateCreator } from 'zustand';

import { CurrentStatuses, UserInfo } from '@/types';

type LoadingProps = {
  linkDetails?: boolean;
  restaurant?: boolean;
};

type InitialLink = {
  sequentialID: string;
  data: {
    billAmount: string;
    reference?: string;
    description?: string;
  };
};

export interface InvoiceSlice {
  selectedTip: number | undefined;
  customTip: string;
  loading: {
    linkDetails: boolean;
    restaurant: boolean;
  };
  currentStatus: CurrentStatuses;
  isYourDetailsOpen: boolean;
  initialLink?: InitialLink;
  userInfo?: UserInfo;
  setSelectedTip: (arg: number | undefined) => void;
  setCustomTip: (arg: string) => void;
  toggleLoader: (arg: LoadingProps) => void;
  setCurrentStatus: (arg: CurrentStatuses) => void;
  setIsYourDetailsOpen: (arg: boolean) => void;
  setInitialLinkData: (arg: InitialLink) => void;
  setUserInfo: (arg: UserInfo) => void;
}

export const createInvoiceSlice: StateCreator<
  InvoiceSlice,
  [],
  [],
  InvoiceSlice
> = set => ({
  selectedTip: undefined,
  customTip: '0',
  loading: {
    linkDetails: true,
    restaurant: true,
  },
  currentStatus: CurrentStatuses.CREATED,
  isYourDetailsOpen: false,
  setSelectedTip: arg => set(() => ({ selectedTip: arg })),
  setCustomTip: arg => set(() => ({ customTip: arg })),
  toggleLoader: arg =>
    set(state => ({ loading: { ...state.loading, ...arg } })),
  setCurrentStatus: arg => set(() => ({ currentStatus: arg })),
  setIsYourDetailsOpen: arg => set(() => ({ isYourDetailsOpen: arg })),
  setInitialLinkData: arg => set(() => ({ initialLink: arg })),
  setUserInfo: arg => set(() => ({ userInfo: arg })),
});
