import { useEffect } from 'react';

import { AppProps } from 'next/app';
import Head from 'next/head';

import { EmotionCache } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

import { SnackbarProvider } from 'notistack';

import {
  ExtendedThemeProvider,
  FirebaseService,
  createEmotionCache,
} from '@/config';
import { ConfigContextProvider } from '@/hooks';
import '@/styles/globals.scss';
import { AuthContextProvider } from '@clubpay/customer-common-module/src/context/auth';
import CssBaseline from '@mui/material/CssBaseline';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: typeof window === 'undefined' ? undefined : window.localStorage,
});

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});

export interface ExtendedAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function App(props: ExtendedAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  useEffect(() => {
    FirebaseService.getInstance();
  }, []);

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
        />
      </Head>
      <ExtendedThemeProvider emotionCache={emotionCache}>
        <SnackbarProvider
          maxSnack={3}
          disableWindowBlurListener
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <AuthContextProvider>
            <ConfigContextProvider>
              <QueryClientProvider client={queryClient}>
                <CssBaseline />
                <Component {...pageProps} />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ConfigContextProvider>
          </AuthContextProvider>
        </SnackbarProvider>
      </ExtendedThemeProvider>
    </>
  );
}

export default App;
